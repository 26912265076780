.card {
    border-radius: 1rem;
}

.card-title {
    text-align: center;
}

.card-body {
    display: flex;
}