.container {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/cover.jpg");
    height: 100vh;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 100px;
}

.title {
    color: #fff;
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

.message {
    font-size: 1rem;
    color: #fff;
    margin-bottom:1.5em;
}

.button {
    width: 100%;
    font-size: 1.5rem;
    padding: 0.3em;
    /* linear gradient from top to bottom #D8AF7E 0%, #9C7F5C 100% */
    background: linear-gradient(180deg, #d8af7e 0%, #9c7f5c 100%);
    border-radius: 10px;
    color: #fff;
    border: none;
    text-transform: uppercase;
    /* Establish a text border */
    -webkit-text-stroke: 1px #50412f;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
}

.boxes {
    display: flex;
    flex-direction: row;

}