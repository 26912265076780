.container {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/cover.jpg");
    height: 100vh;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 100px;
}

.form {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    color: #fff;
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

.icon {
    font-size: 2rem;
}

.email {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: solid 1px #fff;
    color: #fff;
    border-radius: 5px;
    padding: 7px 10px;
    width: 100%;
    background: rgba(217, 217, 217, 0.3);
    margin-bottom: 1.5em;
}

.email input {
    margin-left: 0.5em;
    color: white;
    border: none;
    background-color: transparent;
    font-size: 1.4rem;
}

.email input::placeholder {
    color: white;
}

.email input:focus {
    outline: none;
}

.button {
    width: 100%;
    font-size: 1.5rem;
    padding: 0.3em;
    /* linear gradient from top to bottom #D8AF7E 0%, #9C7F5C 100% */
    background: linear-gradient(180deg, #d8af7e 0%, #9c7f5c 100%);
    border-radius: 10px;
    color: #fff;
    border: none;
    text-transform: uppercase;
    /* Establish a text border */
    -webkit-text-stroke: 1px #50412f;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
}

.link {
    display:block;
    font-size: 0.7rem;
    color: white;
    margin-top: 0.5em;
}

.options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.error {
    color: #fff;
    margin-top: 1em;
}

.message {
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 1em;
}

.progress {
    width: 100%;
}