.header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("../images/cover.jpg");
    height: 100vh;
    background-position: center;
    background-size: cover;
}
.nav {
    padding: 30px 10%;
    font-size: 1.5rem;
    font-weight: bold;
}

/* remove the default list style */
.menu {
    list-style-type: none;
    display: flex;
    justify-content: end;
}

.menu_item:last-child {
    margin-left: 20px;
}

.link {
    text-decoration: none;
    color: white;
}

.menu_item__left {
    flex: 1;
}

.hero_image {
    width: 100px;
    margin-left: calc(10% - 15px);
    display: block;
}

.hero_text {
    color: white;
    font-size: 100px;
    margin-left: 10%;
    margin-right: 10%;
    font-weight: bold;
}

.hero_info {
    color: white;
    font-size: 24px;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2em;
}

@media screen and (max-width: 768px) {
    .nav {
        padding: 15px 5%;
        font-size: 18px;
        font-weight: bold;
    }

    .hero_image {
        margin-left: calc(5% - 15px);
    }

    .hero_text {
        font-size: 50px;
        margin-left: 5%;
        margin-right: 5%;
    }

    .hero_info {
        font-size: 16px;
        margin-left: 5%;
        margin-right: 5%;
    }
}
